<template>
  <div class="info-block_wrapper_size">
    <h2>
      <slot name="title"></slot>
    </h2>
    <slot name="subtitle"></slot>
    <br />
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
