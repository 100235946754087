<template>
  <base-table :fields="fields" :items="[...dialerData]" :isFooter="isTableFooter">
    <template #headerText> Состояние задания "{{ dialerData.Caption }}" </template>
    <template #cell(AttemptsCount)="{item}"> {{ item.AttemptsCount }} </template>
    <template #footer>
      <b-row>
        <b-col class="dflex valign-center">
          <BaseButton
            v-if="dialerData.LastLunchTaskCurrentStatus < 2"
            @click="sendCommand(URL.START_DIALER)"
          >
            <template #icon>
              <b-icon icon="arrow-repeat"></b-icon>
            </template>
            <template #text>Запустить с начала</template>
          </BaseButton>
          <span v-else-if="dialerData.LastLunchTaskCurrentStatus === 2">
            Формируем список абонентов
          </span>
          <BaseButton
            v-else-if="dialerData.LastLunchTaskCurrentStatus === 3"
            @click="sendCommand(URL.STOP_DIALER)"
          >
            <template #icon>
              <b-icon icon="stop-fill"></b-icon>
            </template>
            <template #text>Остановить</template>
          </BaseButton>
          <span
            v-else-if="
              dialerData.LastLunchTaskCurrentStatus === 4 ||
                dialerData.LastLunchTaskCurrentStatus === 5
            "
          >
            Производится отмена
          </span>
        </b-col>
        <!-- Заготовка блока на будущее (для кнопки настройки) -->
        <!-- <b-col cols="6" md="4" lg="3">
          <BaseButton>
            <template #text>Настроить шаблон</template>
          </BaseButton>
        </b-col> -->
      </b-row>
    </template>
  </base-table>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { sendCustomerRequest } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import URL from '@/basUrl';

export default {
  data() {
    return {
      isTableFooter: true,
      fields: [
        { key: 'AttemptsCount', label: 'Всего попыток' },
        { key: 'NotifiedNumbersCount', label: 'Оповещённых номеров' },
        { key: 'TotalNumbersCount', label: 'Всего номеров' },
        { key: 'CurrentAasAutoDialerStatus', label: 'Статус последнего запуска' }
      ],
      query: this.$route.query.id,
      requestRaw: JSON.stringify({
        TemplateId: this.$route.query.id,
        IsAasTemplate: true
      }),
      URL
    };
  },

  components: {
    BaseTable,
    BaseButton
  },

  computed: mapGetters({ dialerData: 'autoDialer/dialerData' }),

  mounted() {
    this.checkStatus();
  },

  methods: {
    ...mapActions({ getDetails: 'autoDialer/getDetails' }),
    ...mapMutations({ SAVE_DETAILS: 'autoDialer/SAVE_DETAILS' }),

    checkStatus() {
      const interval = setInterval(async () => {
        await this.getDetails(this.query);
        if (this.dialerData.LastLunchTaskCurrentStatus < 2) clearInterval(interval);
      }, 3000);
    },

    sendCommand(url) {
      sendCustomerRequest(url, this.requestRaw)
        .then(() => this.checkStatus())
        .catch((e) => catchFunction(e));
    }
  }
};
</script>

<style></style>
