<template>
  <base-table :fields="fields" :items="dialerData.Contacts">
    <template #headerText>
      Список контактов для обзвона в задании "{{ dialerData.Caption }}"
    </template>
    <template #cell(ContactName)="{item}">
      {{ item.ContactName }}
    </template>
    <template #cell(LastOperationDateTime)="{item}">
      {{ item.LastOperationDateTime.replace(/T/g, ' ') }}
    </template>
  </base-table>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      fields: [
        { key: 'ContactName', label: 'Абонент' },
        { key: 'PhoneNumber', label: 'Номер телефона' },
        { key: 'ListenedCount', label: 'Совершено попыток' },
        { key: 'LastOperationDateTime', label: 'Дата' },
        { key: 'AdditionalParameter', label: 'Статус последней попытки' }
      ]
    };
  },

  components: {
    BaseTable
  },

  computed: mapGetters({ dialerData: 'autoDialer/dialerData' })
};
</script>

<style></style>
