<template>
  <the-wrapper :isSpinner="isSpinner">
    <base-service>
      <template #title> Просмотр задания "{{ dialerData.Caption }}" </template>
      <template #body>
        <DialerStatus />
        <DialerContacts />
      </template>
    </base-service>
  </the-wrapper>
</template>

<script>
import BaseService from '@/components/shared/BaseService.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import DialerStatus from '@/components/services/auto_dialer/dialer_details/DialerStatus.vue';
import DialerContacts from '@/components/services/auto_dialer/dialer_details/DialerContacts.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      query: this.$route.query.id,
      isSpinner: true
    };
  },

  components: {
    BaseService,
    DialerStatus,
    DialerContacts,
    TheWrapper
  },

  created() {
    this.getDetails(this.query);
    this.SHOW_LOADING(false);
  },

  computed: mapGetters({ dialerData: 'autoDialer/dialerData' }),

  methods: {
    ...mapActions({ getDetails: 'autoDialer/getDetails' }),
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' })
  }
};
</script>

<style></style>
